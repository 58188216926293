import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {
  @Input() images: any[] = []
  @Input() ingreso: boolean = true
  @Input() marginDesk: string = "0px"
  @Input() marginMob: string = "0px"
  apiUrl = environment.apiUrl;
  isDesktop: boolean;
  language: any;

  constructor(private aRouter: ActivatedRoute) {
    this.language = this.aRouter.snapshot.params['language'];
    this.isDesktop = window.innerWidth > 768;

  }
  ngOnInit(): void {
    this.cambiarSliderMob(0)
    this.cambiarSliderDesk(0)
    setInterval(() => {
      if (this.isDesktop) {
        this.positionDesk++;
        this.cambiarSliderDesk(this.positionDesk);
      } else {
        this.positionMob++;
        this.cambiarSliderMob(this.positionMob);
      }

    }, 10000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop = event.target.innerWidth > 768;
  }
  positionDesk = 0;
  positionMob = 0;

  cambiarSliderDesk(i: number) {
    if (i >= this.images.length) {
      i = 0;
    }
    if (i < 0) {
      i = this.images.length - 1;
    }

    this.images.forEach(f => {
      f.state = 'inactive';
    })
    this.images[i].state = 'actived'
    this.positionDesk = i;
  }
  cambiarSliderMob(i: number) {
    if (i >= this.images.length) {
      i = 0;
    }
    if (i < 0) {
      i = this.images.length - 1;
    }

    this.images.forEach(f => {
      f.state = 'inactive';
    })
    this.images[i].state = 'actived'
    this.positionMob = i;
  }

}
