
<a  id="boton-area"  [href]="'/es/'+item.link" [attr.data-name]="item.name" 
    class="item-custom" [style.border-color]="item.color" [style.border-color]="item.borderColor" [style.background-color]="item.backgroundColor">
    <div style="display: flex; align-items: center;
             " class="area">     
        <img *ngIf="svgContent" [src]="svgContent" class="img-c" />
        <img *ngIf="!this.isSvg(item.file) && !svgContent" [src]="createLink(item.file)" alt="" class="img-c">
        <span *ngIf="item.name" class=" quicksand-regular item-txt" [style.color]="item.colorName">
            {{item.name}}
        </span>
    </div>
</a>

