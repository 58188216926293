import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { CardsGeneralDosComponent } from 'src/app/modules/general/components/cards-general-dos/cards-general-dos.component';
import { BotonHeader } from 'src/app/shared/models/botonHeader';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { CabeceraComponent } from 'src/app/modules/general/components/cabecera/cabecera.component';
import { CardsGeneralComponent } from 'src/app/modules/general/components/cards-general/cards-general.component';
import { CarrouselGeneralComponent } from 'src/app/modules/general/components/carrousel-general/carrousel-general.component';
import { CustomDiaryGeneralComponent } from 'src/app/modules/general/components/custom-diary-general/custom-diary-general.component';
import { CustomStatsGeneralComponent } from 'src/app/modules/general/components/custom-stats-general/custom-stats-general.component';
import { FormContactoComponent } from 'src/app/modules/general/components/form-contacto/form-contacto.component';
import { ListaDeLinksComponent } from 'src/app/modules/general/components/lista-de-links/lista-de-links.component';
import { PostComponent } from 'src/app/modules/general/components/post/post.component';
import { PrimeraFotoDestacadaComponent } from 'src/app/modules/general/components/primera-foto-destacada/primera-foto-destacada.component';
import { TextItemGeneralComponent } from 'src/app/modules/general/components/text-item-general/text-item-general.component';
import { VideoMasFormularioComponent } from 'src/app/modules/general/components/video-mas-formulario/video-mas-formulario.component';

@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.scss']
})
export class IngresoComponent implements OnInit {
  language: string;
  titleLink: string;
  breadcump: string;
  countFamily: number = 1;
  pathMob: any;
  singlepage: any;
  count = 0;
  data: any;
  arrayRowsSize: any;
  arrayRows: any[] = [];
  itemCarrouselHeader: any[] = [];
  title: string;
  path: string;
  link: string;
  botones: BotonHeader[] = [];
  benefitsButtons: any[];
  apiUrl: any
  /*@ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;*/
  viewContainerRefs: any[] = []
  rowsLength: any;
  rows: any[];
  rowsSize: any[] = []
  id_component: any;
  private isMobileSubject = new BehaviorSubject<boolean>(false);
  isMobile$ = this.isMobileSubject.asObservable();
  isMob: boolean
  @ViewChild('videoPlayer') videoPlayer: any;
  @ViewChild('videoPlayerMob') videoPlayerMob: any;
  images = [
    {
      id: "banner-orientacion-vocacional",
      path: 'assets/img/Orientacion-vocacional.jpg',
      pathMobile: 'assets/img/Orientacion-vocacional-mobile.jpg',
      redirect: '/es/formacion-preuniversitaria/en-que-consiste-el-curso-de-ingreso-a-la-universidad'
    },
    {
      id: "banner-experiencia-uca",
      path: 'assets/img/Experiencia-UCA.jpg',
      pathMobile: 'assets/img/Experiencia-UCA-mobile.jpg',
      redirect: '/es/experiencia-uca-pais-1'
    },
    {
      id: "banner-becas",
      path: 'assets/img/Becas.jpg',
      pathMobile: 'assets/img/Becas-mobile.jpg',
      redirect: '/es/institucional/alumnos/becas'
    },
    {
      id: "banner-pasantias",
      path: 'assets/img/Pasantias.jpg',
      pathMobile: 'assets/img/Pasantias-mobile.jpg',
      redirect: '/es/institucional/alumnos/pasantias-y-ofertas-laborales'
    },



  ];
  dataBuscador ={
      "title": "ELEGÍ TU CARRERA",
      "color_title": "#ffffff",
      "subtitle": "Encontrá toda la información sobre la carrera que te interesa",
      "color_subtitle": "#ffffff",
      "background_color": "#003567"
    }
 
  dataSedes = {
    backgroundColor: "rgb(0, 58, 115)",
    btnColor: "#5a8abe",
    label: "Conocé nuestras carreras",
    title: "NUESTRAS SEDES",
    titleColor: "#ffffff"
  }
  dataPortada;

  constructor(private _sanitizer: DomSanitizer, private componentFactoryResolver: ComponentFactoryResolver, public router: ActivatedRoute, public meta: Meta,
    public route: Router,
    public services: GeneralService) {
    this.apiUrl = environment.apiUrl;
    this.id_component = this.router.snapshot.params['id'];
    let url = window.location.href;
    if (url.includes('#')) {
      let l = this.router.snapshot.params['language'];
      let index = url.indexOf("#")
      let palabra = url.slice((index + 1), url.length)
      window.location.href = "/" + l + "/busqueda/ingreso#" + palabra;
    }
    this.checkScreenWidth();
  }

  ngOnInit(): void {

    this.router.params.forEach((params: Params) => {
      this.language = params['language'];
      this.services.getIngresoEntities(this.language).subscribe(data => {
        Object.keys(data.data[0]).forEach((key) => {
          var arrayValue = data.data[0];
          if (!isNaN(Number(key))) {
            this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
              this.definirComponentes(Number(key), arrayValue[key][0], 8, data1)
            });
          }
        });
      });
    });




  }
  ngAfterViewInit() {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = true
      this.videoPlayer.nativeElement.play();
      this.videoPlayerMob.nativeElement.muted = true
      this.videoPlayerMob.nativeElement.play();
    }

  }
  private checkScreenWidth() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.isMob = true
      } else {
        this.isMob = false
      }
    });
  }

  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];

    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=' + url + ';controls=0&amp;disablekb=1&amp;fs=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;showinfo=0');
  }

  definirComponentes(index: number, element: any, width: number, data1: any) {
    let info = data1.data[0];
    if (info.cover) {
      info.cover.video_path = "/public/filters/careerPhoto/public/videos_portadas/PORTADA%20GRADO%20%201300-525.mp4";
      info.cover.video_pathMobile = "/public/filters/careerPhoto/public/videos_portadas/PORTADA%20GRADO%20%20835-796.mp4";
      info.cover.pathMobile = null;
      info.cover.path = null;
      info.cover.check_capa = true;
      this.dataPortada = info;
      this.benefitsButtons = info.benefitsButtons
    }
  }
  newComponent(index: number, type: string, info: any, width: number, background?: string) {

    switch (type) {
      case 'Primera foto destacada': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PrimeraFotoDestacadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info['entityList'][0].entity;
        ref.instance.title = info['entityList'][0].title;
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Simple': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.instance.background = background;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'CustomPublication': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.publication;
        ref.instance.title = 'Publicaciones';
        ref.instance.background = background;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Noticias con resumen': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PostComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Carrousel':
        if (info.entityList[0].entity) {
          let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
          let ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.changeDetectorRef.detectChanges();
        }
        break;
      case 'Cards':
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.socialNetwork.socialNetworkItem;
        ref.instance.title = info.socialNetwork.title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
        break;
      case 'Lista de links':
        if (info.entityList[0].entity) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.background = background;
          ref.changeDetectorRef.detectChanges();
        }
        break;

      case 'Video + Formulario (en ingles)':
        if (info.entityList[0].linkCode) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
          );
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.idioma = 'en';
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'Video + Formulario (en español)':
        if (info.entityList[0].linkCode) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
          );
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.idioma = 'es';
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break;

      case 'CustomForm': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Diary': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        if (index == 2) {
          width = 4;
        }
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.diary[0].diaryItem;
        ref.instance.title = info.diary[0].title;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'CustomStats': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.CustomStats;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Cabecera': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.title = info.entityList[0].title;
        ref.instance.slider = info.entityList[0].entity;
        ref.instance.background = info.entityList[0].background;
        ref.instance.path = info.entityList[0].path;
        ref.instance.width = width;
        ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
        ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
        ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
        ref.changeDetectorRef.detectChanges();

      }
        break;


    }






  }

  viewContainerRefA(index: number): any {
    /* switch (index) {
       case 2:
         return this.viewContainerRef2;
       case 3:
         return this.viewContainerRef3;
       case 4:
         return this.viewContainerRef4;
       case 5:
         return this.viewContainerRef5;
       case 6:
         return this.viewContainerRef6;
       case 7:
         return this.viewContainerRef7;
       case 8:
         return this.viewContainerRef8;
       case 9:
         return this.viewContainerRef9;
       case 10:
         return this.viewContainerRef10;
       case 11:
         return this.viewContainerRef11;
       case 12:
         return this.viewContainerRef12;
       default:
         // 
         break;
     }*/
  }
}
