<div class="ingreso-container">
  
    <app-portada *ngIf="dataPortada" [data]="dataPortada"></app-portada>
   


    <app-buscador-carreras [data]="dataBuscador" [padding]='"30px 35.6px 30px 35.6px"' [translateY]="'110'"></app-buscador-carreras>

  <section id='nuestras-sedes'>
    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-nuestras-sedes [data]="dataSedes"></app-nuestras-sedes>
        </div>
      </div>
    </div>
  </section>
  <section class="" *ngIf='benefitsButtons'>
    <app-icons *ngIf='benefitsButtons' [data]='benefitsButtons' [ingreso]="true" [images]="images"></app-icons>
   
  </section>
  <section id='bienvenida' class="bienvenida">
    <app-bienvenida></app-bienvenida>
   
  </section>


  <section id=''>
    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-items-ingreso *ngIf='benefitsButtons' [data]='benefitsButtons'></app-items-ingreso>
        </div>
      </div>
    </div>
  </section>
</div>

<!--<ng-container #componenteDinamico2></ng-container>
<ng-container #componenteDinamico3></ng-container>
<ng-container #componenteDinamico4></ng-container>
<ng-container #componenteDinamico5></ng-container>
<ng-container #componenteDinamico6></ng-container>
<ng-container #componenteDinamico7></ng-container>
<ng-container #componenteDinamico8></ng-container>
<ng-container #componenteDinamico9></ng-container>
<ng-container #componenteDinamico10></ng-container>
<ng-container #componenteDinamico11></ng-container>
<ng-container #componenteDinamico12></ng-container>-->