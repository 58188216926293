import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';

import { PortadaComponent } from 'src/app/shared/components/portada/portada.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/components/areas-de-conocimiento/areas-de-conocimiento.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/components/formas-de-contacto/formas-de-contacto-dos.component';

import { BuscadorPosgradosComponent } from 'src/app/modules/posgrados/components/buscador-posgrados/buscador-posgrados.component';
import { IconsComponent } from 'src/app/modules/general/components/icons/icons.component';
import { NuestrasSedesComponent } from 'src/app/modules/general/components/nuestras-sedes/nuestras-sedes.component';
import { TextItemGeneralComponent } from 'src/app/modules/general/components/text-item-general/text-item-general.component';

@Component({
  selector: 'app-posgrados',
  templateUrl: './posgrados.component.html',
  styleUrls: ['./posgrados.component.scss']
})
export class PosgradosComponent implements OnInit {
  language: string;

  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }
  id: any;
  id_component: string;
  apiUrl: any;



  constructor(private componentFactoryResolver: ComponentFactoryResolver,public router: ActivatedRoute, public meta: Meta,
    public route: Router,
    public services: GeneralService) {
    this.apiUrl = environment.apiUrl;
    this.id_component = this.router.snapshot.params['id'];
    let url = window.location.href;
    if (url.includes('#')) {
      let l = this.router.snapshot.params['language'];
      let index = url.indexOf("#")
      let palabra = url.slice((index + 1), url.length)
      window.location.href = "/" + l + "/busqueda/posgrados#" + palabra;
    }

  }
  ngAfterViewInit() {
    var url = decodeURIComponent(window.location.href);
    this.id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, data1)
          });
        }
      });
    })



  }
  ngOnInit(): void {


    this.router.params.forEach((params: Params) => {
      this.language = params['language'];
    });





  }
  SortArray(x, y) {
    if (x.title < y.title) { return -1; }
    if (x.title > y.title) { return 1; }
    return 0;
  }


  refBuscador;
  newComponent(index: number, type: string, info: any) {
    console.log("----" + type + "----")
    console.log(info)
    switch (type) {
      case "PortadaAreas":
      case "Portada": {

        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data[0];

      }
        break;
      case 'Buscador': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorPosgradosComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        this.refBuscador.instance.data = info.data;
        this.refBuscador.instance.translateY = 110;
        this.refBuscador.instance.padding = "30px 38.6px 30px 38.6px";
        this.refBuscador.changeDetectorRef.detectChanges();
      }
        break;
      case "AreaPosgrado": {

        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        const applyStyles = (areas: any[]) => {
          areas?.forEach(e => {
            e.backgroundColor = "#414141";
            e.colorName = "white";
          });
        };
        applyStyles(info.data[0].areas_tematicas_not_check_school);
        applyStyles(info.data[0].areas_tematicas_check_school);
        ref.instance.data = info.data[0];

      }
        break;
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data;
        ref.instance.esCurso = false

      }
        break;
      case "Headquarters": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(NuestrasSedesComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0].headquarters;
        ref.instance.sedes = info.data[0].sedes;
      }
        break
      case "ImageSlider": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(IconsComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.ingreso = false;
        ref.instance.marginDesk = info.data[0].image.margin_desktop;
        ref.instance.marginMob = info.data[0].image.margin_mobile;
        ref.instance.images = info.data[0].image[0];
      }
        break
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break

    }






  }


}
