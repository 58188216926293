import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-buscador-posgrados',
  templateUrl: './buscador-posgrados.component.html',
  styleUrls: ['./buscador-posgrados.component.scss']
})
export class BuscadorPosgradosComponent implements OnInit {
  @Input() sede;
  @Input() data: any
  @Input() padding;
  @Input() paddingMob;
  @Input() translateY;
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  nivelPrioridad = ['Doctorado', 'Maestría', 'Especialización', 'Curso de Posgrado'];
  regiones: any[] = [];
  carreras: Carrera[] = [];
  niveles: Carrera[] = [];
  areas: Carrera[] = [];
  facultades: Carrera[] = [];
  modalidades: Carrera[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: number[] = [];
  numberActive: number = 1;
  pageSize = 15;
  spinner = true;
  filtro = false;
  language = "es";
  destacadas = true;
  @Input() detallePosgrado: boolean = false
  @ViewChild('swipeContainer') swipeContainer2!: ElementRef;
  @ViewChild('busqueda') busqueda!: ElementRef;
  @ViewChild('resultadosSwipe') resultadosSwipe: ElementRef;
  isDesktop: boolean;

  constructor(public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder, private renderer: Renderer2, private el: ElementRef) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      modalidad: ['',],
      facultad: ['',],
      palabras: ['',],
    });
    this.servicesGeneral.getPosgrados(null, null, null, null, null, null, null, this.destacadas).subscribe(data => {
      this.areas = data.data[0].area;
      this.facultades = data.data[0].facultades;
      this.regiones = data.data[0].region;
      this.modalidades = data.data[0].modalidad;
      this.niveles = data.data[0].careerType;
      this.regiones = data.data[0].region;
      if(this.sede) this.buscador.controls['sede'].setValue(this.sede?.id)

      this.filtrar(false);

    })
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkDeviceType()
  }



  ngOnInit(): void {
    this.checkDeviceType();
    this.spaceBetweenElements();

  }
  redirect(c) {
    window.location.href = this.language + c.linkCareer
  }
  checkDeviceType() {
    this.isDesktop = window.innerWidth >= 768;
    if (!this.isDesktop) {
      this.pageSize = 10
    }

    if (this.isDesktop) {
      $(".buscador-container").css("padding", this.padding);
      $(".buscador-container").css("transform", "translateY(-" + this.translateY + "px)");
      $(".resultados").css("margin-top", "-" + (this.translateY - 10) + "px");
      $(".resultados").css("padding-bottom", (this.resultados.length == 0) ? "5px" : "-90px");
    } else {
      $(".buscador-container").css("padding", this.paddingMob + "px");
      $(".buscador-container").css("transform", "translateY(-" + 0 + "px)");
      $(".resultados").css("margin-top", (this.resultados.length == 0) ? "0px" : "20px");
      $(".resultados").css("padding-bottom", (this.resultados.length == 0) ? "0px" : "-90px");

    }
    this.spaceBetweenElements();


  }
  filtrar(event) {
    $(".resultados-principal").css("margin-top", "-90px");
    this.spinner = true;
    this.filtro = true;

    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.buscador.get('area').value;
    var palabras = this.buscador.get('palabras').value;
    var modalidad = this.buscador.get('modalidad').value;
    var facultad = this.buscador.get('facultad').value;
    let first = false
    this.destacadas = sede == '' && nivel == '' && area == '' && palabras == '' && modalidad == '' && facultad == '';
    let scrollear = true

    if (palabras && sede == '' && nivel == '' && area == '' && modalidad == '' || this.sede != '') {
      scrollear = false
    }
    this.servicesGeneral.getPosgrados(sede, nivel, area, palabras, facultad, modalidad, facultad, this.destacadas).subscribe(data => {
      let aux = [];
      this.resultados = [];
      this.carreras = [];
      if (event) {
        this.areas = data.data[0].area;
        this.facultades = data.data[0].facultades;
        this.regiones = data.data[0].region;
        this.modalidades = data.data[0].modalidad;
        this.niveles = data.data[0].careerType;
        first = true
      }
      if (data.data[0].career) {
        data.data[0].career.sort((a, b) => {
          return this.nivelPrioridad.indexOf(a.nivel) - this.nivelPrioridad.indexOf(b.nivel);
        });
        data.data[0].career.forEach(element => {
          element.title = this.cortarTitulo(element.title);
          aux.push(element)
        });
        this.carreras = aux.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });
      }

      this.cambiarPagina(1, first, scrollear)

    })
  }
  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    this.filtrar(null);
  }

  spaceBetweenElements() {

    if (this.carreras.length == 0) {
      $(".fondo-area").css("margin-top", "0px");
      $(".fondo-area").css("padding-top", "0px");
      $(".resultados-principal").css("margin-top", "-140px");
      $(".resultados ul").css("padding", "0px!important");

    } else {
      let altura = $('.buscador-container').outerHeight();
      $(".fondo-area").css("margin-top", "-160px");
      $(".fondo-area").css("padding-top", "180px");
      $(".resultados ul").css("padding", "14px 0px 0px 0px");

    }
  }

  cambiarPagina(page, first, scrollear) {

    this.pages = [];
    //  let resultadoFiltrado = this.filtros(modalidad);
    this.resultados = this.carreras.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.carreras.length / this.pageSize)

    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    this.checkDeviceType();
    this.spaceBetweenElements();

    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;
    if (!first && scrollear || !this.isDesktop && scrollear && !first) {
      //this.busqueda.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (this.resultadosSwipe) {
        const resultadosElement = this.resultadosSwipe.nativeElement;
        const yOffset = -100
        const y = resultadosElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }

  primerPaginaHabilitada(): boolean {
    return this.numberActive > 1;
  }

  ultimaPaginaHabilitada(): boolean {
    return this.numberActive < this.pages.length;
  }

  get rangoResultados(): string {
    const startIndex = (this.numberActive - 1) * this.pageSize + 1;
    const endIndex = Math.min(startIndex + this.pageSize - 1, this.carreras.length);
    return `${startIndex}-${endIndex} de ${this.carreras.length}`;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }
  formatearFecha(value: any): any {
    if (value) {
      const date = new Date(value);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];

      return `${day} de ${monthName}`;
    } else {
      return 'CONSULTAR'
    }

  }
  ngOnDestroy() {

  }
  cortarTitulo(titulo) {
    if (titulo.length > 50) {
      return titulo.slice(0, 50) + "...";
    } else {
      return titulo;
    }
  }
}


