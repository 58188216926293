import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IframeComponent } from 'src/app/shared/components/iframe/iframe.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nuestras-sedes',
  templateUrl: './nuestras-sedes.component.html',
  styleUrls: ['./nuestras-sedes.component.scss']
})
export class NuestrasSedesComponent implements OnInit {

  @Input() data: any = {};
  language: any;
  apiUrl: any;
  @Input() sedes = [
    {
      title: 'Buenos Aires',
      direccion: 'Av. Alicia Moreau de Justo 1300 (C1107AAZ), C.A.B.A ',
      facebook: 'https://es-la.facebook.com/ucaingreso/',
      instagram: 'https://www.instagram.com/ingreso.uca/?hl=es',
      whatsapp: 'https://api.whatsapp.com/send?phone=5491130234624&app=facebook&entry_point=page_cta',
      imgStatic: 'Campus_Puerto_Madero.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy',
      redirec: 'buenos-aires',
      tel: '5491130234624'
    },
    {
      title: 'Mendoza',
      direccion: 'Uruguay 750 (M550AYH) Godoy Cruz, Mendoza',
      facebook: 'https://es-la.facebook.com/UCA-Ingreso-Mendoza-402324053882458/',
      instagram: 'https://www.instagram.com/ucaingresomendoza/?hl=es-la',
      whatsapp: '',
      imgStatic: 'Campus_Mendoza2.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy?sede_de_interes=Mendoza',
      redirec: 'mendoza',
      tel: '2612056477'
    },
    {
      title: 'Paraná',
      direccion: 'Buenos Aires 249 (E3100BQF) Paraná, Entre Ríos',
      facebook: 'https://www.facebook.com/UCAIngresoParana/',
      instagram: 'https://www.instagram.com/ucaingresoparana/?hl=es-la',
      whatsapp: 'https://api.whatsapp.com/send?phone=543434735453&app=facebook&entry_point=page_cta',
      imgStatic: 'Campus_Parana2.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy?sede_de_interes=Paran%C3%A1',
      redirec: 'paraná',
      tel: '543434735453'
    },
    {
      title: 'Rosario',

      direccion: 'Av. Pellegrini 3314 (S2002QEO) Rosario ,Santa Fe',
      direccion2: '(S2002QEO) ',
      direccion3: 'Rosario , Santa Fe',
      facebook: 'https://www.facebook.com/ucaingresorosario/',
      instagram: 'https://www.instagram.com/ucaingresorosario/?hl=es-la',
      whatsapp: 'https://api.whatsapp.com/send?phone=543412662910&app=facebook&entry_point=page_cta',
      imgStatic: 'Campus_Rosario.jpg',
      contact: 'https://share.hsforms.com/1suaJm4O0SkmS_5I2Cv3RDQe2ugy?sede_de_interes=Rosario',
      redirec: 'rosario',
      tel: '543412662910'
    }
  ]
  constructor(public dialog: MatDialog, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

    this.apiUrl = environment.apiUrl;
  }

  getFormattedAddress(address: string) {
    if (window.innerWidth <= 768) {
      return address.replace(/,/g, ',<br>');
    }
    return address;
  }
  ngOnInit(): void {
  }
  windowOpen(url) {
    window.open(url)
  }
  redirecWhatsApp(tel: string) {
    const url = `https://api.whatsapp.com/send?phone=${tel}`;
    window.open(url, '_blank');
  }
  redirecGoogleMaps(direccion: string): string {
    const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedDireccion = encodeURIComponent(direccion);
    return `${baseUrl}${encodedDireccion}`;
  }
  redirec(link) {
    window.location.href = link
  }


  openModal(url: any) {
    this.dialog.open(IframeComponent, {
      width: '90%',
      height: '80%',
      data: { url }
    });
  }
}
