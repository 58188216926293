import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from '../../models/observacion';

@Component({
  selector: 'app-buscador-posgrados-tematico',
  templateUrl: './buscador-posgrados-tematico.component.html',
  styleUrls: ['./buscador-posgrados-tematico.component.scss']
})
export class BuscadorPosgradosTematicoComponent implements OnInit {
  @Input() data:any= {background_color:"#c1b283"};
  @Input() area :any= {};
  @Input() titulo;
  @Input() translateY = 75;
  @Input() padding="38.6px 35px";
  @Input() paddingMob= "35px";
  language;
  nivelPrioridad = ['Doctorado', 'Maestría', 'Especialización', 'Curso de Posgrado','Curso Acreditable'];
  titulos = [
    { singular: 'Doctorado', plural: "Doctorados" },
    { singular: 'Maestría', plural: "Maestrías" },
    { singular: 'Especialización', plural: "Especializaciones" },
    { singular: 'Curso de Posgrado', plural: "Cursos de Posgrado" },
    { singular: 'Curso Acreditable', plural: "Cursos Acreditables" },
  ];
  spinner = true;
  filtro = false;
  numberActive: number = 1;
  regiones: string[] = [];
  carreras: any
  niveles: Carrera[] = [];
  areas: Carrera[] = [];
  facultades: Carrera[] = [];
  modalidades: Carrera[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  carrerasArray: any
  carrerasAux: any

  constructor( private form: FormBuilder, private services: GeneralService, public route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      modalidad: ['',],
      facultad: ['',],
      palabras: ['',],
      venueId: ['',],
    });
  
   }

  ngOnInit(): void {
    this.filtrar();
    this.services.getFacultadByArea(this.area.name).subscribe(data => {
        data.data[0].venue.forEach(facultadad => {
          facultadad.title = this.eliminarPrefijoFacultad(facultadad.title);
          this.facultades.unshift(facultadad)
        })
  
        data.data[0].region.forEach(sede => {
          sede.title = this.eliminarPrefijoFacultad(sede.title);
          this.regiones.unshift(sede)
        })
      })
  }
  eliminarPrefijoFacultad(cadena: string): string {
    const prefijo = 'Facultad de';
    if (cadena && cadena.startsWith(prefijo)) {
      return cadena.substring(prefijo.length).trim();
    } else {
      return cadena;
    }
  }

  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    this.filtrar();
  }
  filtrar() {
    this.checkDeviceType()

    this.spinner = true;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.area.name
    var modalidad = this.buscador.get('modalidad').value;
    var palabras = this.buscador.get('palabras').value;
    var venue = this.buscador.get('venueId').value;
    var resultados = document.getElementById("resultados")
    /*  var destac = document.getElementById("destac"); */

    this.services.getPosgrados(sede, nivel, area, palabras, null, modalidad, venue).subscribe(data => {
      let aux = [];
      this.resultados = [];
      this.carreras = [];
      this.niveles = data.data[0].careerType;
      this.modalidades = data.data[0].modalidad;
      this.areas = data.data[0].area;
      if (data.data[0].career) {

        this.carrerasAux = data.data[0].career
        let carrerasOriginales: any

        carrerasOriginales = this.carrerasAux
        const carrerasAgrupadas: { [nivel: string]: { carreras: Carrera[], color: string } } = {};

        carrerasOriginales.forEach(carrera => {
          const nivel = carrera.nivel;

            if (!carrerasAgrupadas[nivel]) {
              carrerasAgrupadas[nivel] = { carreras: [], color: carrera.color };
            }
            carrerasAgrupadas[nivel].carreras.push(carrera);
        
        });

        this.carreras = carrerasAgrupadas;

        this.carrerasArray = Object.entries(this.carreras).map(([nivel, carreras]) => ({ nivel, carreras }));
        this.carrerasArray.sort((a, b) => {
          return this.nivelPrioridad.indexOf(a.nivel) - this.nivelPrioridad.indexOf(b.nivel);
        });
        //asignar titulos pliral o singular
        this.nivelPrioridad.forEach(nombreNivel => {
          let nivel = this.carrerasArray.find(d => d.nivel == nombreNivel);
          let carreras = nivel?.carreras?.carreras;
          if (carreras?.length > 1) {
            let titulo = this.titulos.find(d => d.singular == nombreNivel)
            nivel.nivel = titulo.plural;
          }
        })


      }
      if (sede == '' && nivel == '' && area == '' && modalidad == '' && palabras == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display = 'none';

      } else {
        this.filtro = true
        if (resultados) resultados.style.display = 'block';
        this.spinner = false;
      }
    })
  }
  cortarTitulo(titulo) {
    if (titulo.length > 50) {
      return titulo.slice(0, 50) + "...";
    } else {
      return titulo;
    }
  }


  filtros(modalidad: string): any[] {
    let listaFiltrada = this.carrerasAux;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => {
        if (r.modalidad) {
          return this.processText(r.modalidad) === this.processText(modalidad);
        } /* else {
          return true; 
        } */
      });
    }

    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });
    return listaFiltrada;
  }
  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }
  getCarrerasKeys(): string[] {
    return Object.keys(this.carreras);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkDeviceType()
  }
  checkDeviceType() {
    let isDesktop = window.innerWidth >= 768;
    $(".buscador-container").css("background-color", this.data.background_color + "");
    if (isDesktop) {
      $(".buscador-container").css("padding", this.padding);
      $(".buscador-container").css("transform", "translateY(-" + this.translateY + "px)");
      $(".resultados").css("margin-top", "-" + (this.translateY - 10) + "px");
      $(".resultados").css("margin-bottom", (this.resultados.length == 0) ? 90 : 0 + "px");

    } else {
      $(".buscador-container").css("padding", this.paddingMob);
      $(".buscador-container").css("transform", "translateY(-" + 0 + "px)");
      $(".resultados").css("margin-top", "-" + 0 + "px");
      $(".resultados").css("margin-bottom", (this.resultados.length == 0) ? 120 : 70 + "px");

    }
  }
}
