<!--<img class="imgen-fondo" src="./assets/img/Fondo-cursos.png" alt="">-->
<div class="fondo-area" style="text-align: -webkit-center;   ">
    <div class="" style="    justify-content: center;          ">
        <div id="areas" class="posg-cont-c">
            <app-card-area-conocimiento [item]="item" *ngFor='let item of  items.arriba;'>
            </app-card-area-conocimiento>
        </div>
        <div class="linea" *ngIf="items && items.abajo && items.abajo.length>0"></div>

        <div id="areas" class="posg-cont-c ">
            <app-card-area-conocimiento [item]="item"
                *ngFor='let item of  items.abajo;'></app-card-area-conocimiento>

        </div>
    </div>
</div>