<div class="fondo-contacto">
    <div class="container2">
        <div class="container-izq">
            <img src="../../../../../assets/img/messages-floating-lab&w.png" alt="" class="img-izq">

        </div>
        <div class="container-der" [style.background-color]="data?.background_color">
            <div class="title-wpp">
                <h5 class="contactate quicksand-medium" [style.color]="data?.color_title">{{ data?.title }}</h5>
                <div class="grey" id="boton-wsp">
                    <a style="   margin-bottom: 0px; color: white; align-content: center;" class="text-m nunito-regular"
                        [href]="data?.link_whatsapp" target="_blank">ENVIANOS UN WHATSAPP</a>
                    <img src="../../../../../assets/img/whsatsapp-blanco.png" alt="" class="wpp-icon">
                </div>
            </div>

            <p style="color: white;margin-top:18px; margin-bottom:23px" class="text-small quicksand-medium"
                [style.color]=" data?.color_subtitle">{{data?.subtitle}}</p>
            <form [formGroup]="formDatos" action="" class="form-contacto">
                <!-- <div *ngIf="formDatos.get('name').hasError('required') && formDatos.get('name').touched || formDatos.get('email').hasError('required') && formDatos.get('email').touched"
                        class="text-left">
                        <span class="requerido quicksand-medium">*Estos campos son requeridos </span>
                    </div> -->
                <div class="nombre-email">
                    <input type="text" placeholder="Nombre" class=" nunito-medium input" formControlName='name'
                        matTooltip="Este campo es obligatorio." matTooltipPosition="above"
                        matTooltipClass="custom-tooltip" #nameTooltip="matTooltip">
                    <input type="text" placeholder="E-mail" class=" nunito-medium input" formControlName='email'
                        matTooltip="Este campo es obligatorio y debe ser un mail" matTooltipPosition="above"
                        matTooltipClass="custom-tooltip" #emailTooltip="matTooltip">
                </div>
                <!-- <select formControlName='region' class="input quicksand-medium" matTooltip="Seleccione una sede." matTooltipPosition="above" matTooltipClass="custom-tooltip">
                    <option value=""  >Sede</option>
                    <option value="Buenos Aires">Buenos Aires</option>
                    <option value="Mendoza">Mendoza</option>
                    <option value="Paraná">Paraná</option>
                    <option value="Rosario">Rosario</option>
                </select> -->
                <div class="input nunito-medium" style="padding: 0px !important; text-align: left;"
                    matTooltip="Seleccione una sede." matTooltipPosition="above" matTooltipClass="custom-tooltip"
                    #regionTooltip="matTooltip">
                    <app-custom-select class="select-sede" [options]="sedes" [italic]="false" [ingreso]="true" placeholder="Sede"
                    [optionPlaceholder]="false"  (selectionChange)="updateFilter('region', $event)"
                        [reset]="formDatos.controls['region'].value === null">
                    </app-custom-select>
                </div>
                <textarea formControlName='message' name="" id="" cols="30" rows="1" placeholder="Mensaje"
                    class="input nunito-medium textarea" style="margin-bottom: 2%"></textarea>
                <div class="redes2">
                    <div style="display: flex; align-items: center;">
                        <p class="text-small nunito-medium text-redes"
                            style="margin-bottom: 0px; font-style: italic !important;"
                            [ngClass]="{ 'azul-curso': esCurso }">Seguinos en redes</p>
                        <a id="redes" style='cursor:pointer;color: #007bff;'
                            (click)='windowOpen(data.link_face ? data.link_face : "https://www.facebook.com/UCAPosgrados")'>
                            <img [src]="esCurso ? '../../../../../assets/img/fb2.png' : '../../../../../assets/img/fb-posgrado.png'"
                                alt="" class="img-icon">
                        </a>
                        <a id="redes"  href="" style='cursor:pointer;color: #007bff;'
                            (click)='windowOpen(data.link_inst ? data.link_inst : "https://www.instagram.com/posgradosuca")'>
                            <img [src]="esCurso ? '../../../../../assets/img/ig2.png' : '../../../../../assets/img/ig-posgrado.png'"
                                alt="" class="img-icon icon2">
                        </a>
                    </div>

                    <button (click)='consultar()' class="btn nunito-regular">ENVIAR</button>
                </div>
            </form>

        </div>
    </div>
</div>