import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-areas-de-conocimiento',
  templateUrl: './areas-de-conocimiento.component.html',
  styleUrls: ['./areas-de-conocimiento.component.scss']
})
export class AreasDeConocimientoComponent implements OnInit {
  @Input() data: any;
  @Input() fondo: any;
  @Input() fondoMob: any;
  @Input() cursos: boolean = false;
  width = false;
  arriba: any[] = [];
  abajo: any[] = [];
  constructor() { }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.fondo && this.fondoMob) $(".fondo-area").css("background-image", 'url("../../../../assets/img/' + ((window.innerWidth >= 768) ? this.fondo : this.fondoMob) + '")');
  }
  ngOnInit(): void {
    if (this.fondo && this.fondoMob) $(".fondo-area").css("background-image", 'url("../../../../assets/img/' + ((window.innerWidth >= 768) ? this.fondo : this.fondoMob) + '")');

    this.addElements()
  }

  addElements() {
    this.arriba = this.data.areas_tematicas_not_check_school, 0;
    this.abajo = this.data.areas_tematicas_check_school, 0;
  }
  ngAfterViewInit() {
    this.checkVisibilityAll();

  }
  checkVisibility(id: string): boolean {
    const element = document.getElementById(id);

    if (element && !element.classList.contains('puffIn')) {
      const rect = element.getBoundingClientRect();
      const elementHeight = rect.height;

      // Calcular la cantidad visible del elemento
      const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);

      // Si al menos el 30% del elemento es visible
      const visibilityPercentage = visibleHeight / elementHeight;

      if (visibilityPercentage >= 0.3) {  // Si el 30% del elemento es visible
        $('#' + id).addClass('puffIn');
        return true;
      }
    }

    return false;
  }

  checkVisibilityAll() {
    const elements = document.querySelectorAll('.area-card');
    let groupSize = 3;
    let delay = 50;

    for (let i = 0; i < elements.length; i += groupSize) {
      setTimeout(() => {
        for (let j = i; j < i + groupSize && j < elements.length; j++) {
          const id = elements[j].id;
          if (id) {
            this.checkVisibility(id);
          }
        }
      }, (i / groupSize) * delay);
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
    this.checkVisibilityAll();
  }
}


