import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { FormasDeContactoDosComponent } from 'src/app/shared/components/formas-de-contacto/formas-de-contacto-dos.component';
import { PortadaComponent } from 'src/app/shared/components/portada/portada.component';
import { environment } from 'src/environments/environment';
import { BuscadorPosgradosComponent } from 'src/app/modules/posgrados/components/buscador-posgrados/buscador-posgrados.component';
import { BuscadorPosgradosTematicoComponent } from 'src/app/shared/components/buscador-posgrados-tematico/buscador-posgrados-tematico.component';
import { IconsComponent } from 'src/app/modules/general/components/icons/icons.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/components/areas-de-conocimiento/areas-de-conocimiento.component';
import { NuestrasSedesComponent } from 'src/app/modules/general/components/nuestras-sedes/nuestras-sedes.component';
import { TextItemGeneralComponent } from 'src/app/modules/general/components/text-item-general/text-item-general.component';

@Component({
  selector: 'app-interna-posgrado',
  templateUrl: './interna-posgrado.component.html',
  styleUrls: ['./interna-posgrado.component.scss']
})
export class InternaPosgradoComponent implements OnInit {


  id: any
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;

  language:any;
  area:any;
  sede: any;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }
  constructor(private aRouter: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver, private services: GeneralService, public route: ActivatedRoute) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  

  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    var url = decodeURIComponent(window.location.href);
    this.id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      Object.keys(res.data[0].pages).forEach((key) => {
        this.area = res.data[0].area_posgrado;
        this.sede = res.data[0].region;
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, data1)
          });
        }
      });
    })


  }

  refBuscador: any;
  newComponent(index: number, type: string, info: any) {
    console.log("---" + type + "---")
    switch (type) {
      case "PortadaSedes":
      case "PortadaAreas":
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        console.log(info.data[0]?.cover)

        ref.instance.data = info.data[0];
        ref.instance.area = this.area;
        ref.instance.marginTitle = (this.sede) ? 40 : 75;
        ref.instance.heightDesk = (this.sede) ? "525px" : (this.area) ? "200px" : "";
        ref.instance.heightMob = (this.sede) ? "370px" : (this.area) ? "250px" : "";
        ref.instance.layerPathMob = info.data[0]?.cover?.pathMobileSuperpuesta;
        ref.instance.layerPath = info.data[0]?.cover?.pathSuperpuesta;
       
      }
        break;
      case 'Buscador': {
        let refBuscador;
        if (this.area) {
            let factory1 = this.componentFactoryResolver.resolveComponentFactory(BuscadorPosgradosTematicoComponent);
            refBuscador = (this.viewContainerRefA(index)).createComponent(factory1);
        } else {
            let factory2 = this.componentFactoryResolver.resolveComponentFactory(BuscadorPosgradosComponent);
            refBuscador = (this.viewContainerRefA(index)).createComponent(factory2);
        }
        
        this.refBuscador = refBuscador;
        this.refBuscador.instance.area = this.area;
        this.refBuscador.instance.sede = this.sede;
        this.refBuscador.instance.data = info.data;
        this.refBuscador.instance.translateY = this.sede ? 40 : 75;
        this.refBuscador.instance.padding = "30px 35.6px 30px 35.6px";
      
        this.refBuscador.changeDetectorRef.detectChanges();
        
      }
        break;
      case "AreaPosgrado": {

        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        const applyStyles = (areas: any[]) => {
          areas?.forEach(e => {
            e.backgroundColor = "#414141";
            e.colorName = "white";
          });
        };
        applyStyles(info.data[0].areas_tematicas_not_check_school);
        applyStyles(info.data[0].areas_tematicas_check_school);
        ref.instance.data = info.data[0];

      }
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.data;
        ref.instance.esCurso = false

      }
        break;
      case "Headquarters": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(NuestrasSedesComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);

        ref.instance.data = info.data[0].headquarters;
        ref.instance.sedes = info.data[0].sedes;
      }
        break
      case "ImageSlider": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(IconsComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.ingreso = false;
        ref.instance.marginDesk = info.data[0].image.margin_desktop;
        ref.instance.marginMob = info.data[0].image.margin_mobile;
        ref.instance.images = info.data[0].image[0];
      }
        break
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break




    }






  }

}
